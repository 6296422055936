const full_url = window.location.href;
const query_string_arr = full_url.split("?");
let queryString = "";
let splitPath = "";
if (query_string_arr != null) {
  if(query_string_arr[1] === undefined || query_string_arr[1] === "" || query_string_arr[1] === null){
    splitPath = "/0602UFSN_G0";
  }else{
    queryString = query_string_arr[1];
    splitPath = "/0602UFSN_G0?" + queryString;

  }
}
const urlParams = new URLSearchParams(window.location.search);
export { queryString, urlParams, splitPath };
