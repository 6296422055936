require("dotenv").config();
const AppDomain = window.location.origin;
const AppTitle = process.env.REACT_APP_TITLE;
const AppEnv = process.env.REACT_APP_ENV;
const AppDomainWithoutProtocol = window.location.hostname;
const AppLpDefaultUrl = process.env.REACT_APP_LP_DEFAULT_URL;
const AppAdtopiaUPID = process.env.REACT_APP_ADTOPIA_UPID;
const AppReferrerURL = document.referrer;
const AppLpRedirectDefaultUrl = process.env.REACT_APP_LP_REDIRECT_DEFAULT_URL;
const AppApDefaultUrl = process.env.REACT_APP_AP_DEFAULT_URL;
const AppConversionPixel = process.env.REACT_APP_CONVERSION_PIXEL;
const AppGoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const AppGoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const AppGoogleScopes = process.env.REACT_APP_GOOGLE_SCOPES;
const AppGoogleDiscoveryDoc = process.env.REACT_APP_GOOGLE_DISCOVERY_DOC;
const AppGmailSearchKeyword = process.env.REACT_APP_GMAIL_SEARCH_KEYWORD;
const AppApiUrl = process.env.REACT_APP_API_URL;

export {
  AppDomain,
  AppTitle,
  AppEnv,
  AppDomainWithoutProtocol,
  AppLpDefaultUrl,
  AppAdtopiaUPID,
  AppReferrerURL,
  AppLpRedirectDefaultUrl,
  AppApDefaultUrl,
  AppConversionPixel,
  AppGoogleApiKey,
  AppGoogleClientId,
  AppGoogleScopes,
  AppGoogleDiscoveryDoc,
  AppGmailSearchKeyword,
  AppApiUrl
};