import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import NotFound from "./Components/Pages/NotFound";
import Split_0602UFSN_A1_7 from "./Components/Pages/Splits/SafteyNet/Split_0602UFSN_A1_7";
import Split_0602UFSN_G0 from "./Components/Pages/Splits/SafteyNet/Split_0602UFSN_G0";
import Questionnaire_0602UFSN_A1_7 from "./Components/Pages/Splits/SafteyNet/Questionnaire_0602UFSN_A1_7";
import Thankyou from "./Components/Pages/Thankyou";
import UnQualified from "./Components/Pages/unQualified";
import LandingPage from "./Components/Pages/LandingPage";
import About from "./Components/Includes/Layouts/LandingPage/About";
import Contact from "./Components/Includes/Layouts/LandingPage/Contact";
import Insight from "./Components/Includes/Layouts/LandingPage/Insight";
import Verification from "./Components/Pages/Verification";
import TermsWebsite from "./Components/Pages/TermsWebsite";
import RedirectionUrl from "./Components/Pages/RedirectionUrl";
import Dashboard from "./Components/Pages/Dashboard";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              {/* <Route exact path={["/","/home"]} component={LandingPage} /> */}
              <Route exact path="/" component={NotFound} />
              <Route exact path={["/home"]} component={NotFound} />
              {/* Safetynet Routes */}
              <Route exact path={["/0602UFSN_A1_7"]} component={NotFound} />
              <Route exact path={["/0602UFSN_A1_7/questionnaire"]} component={NotFound} />
              <Route exact path={["/0602UFSN_G0"]} component={NotFound} />
              <Route exact path={["/thank-you"]} component={NotFound} />
              <Route exact path={["/un-qualified"]} component={NotFound} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/about"]} component={NotFound} />
              <Route exact path={["/contact"]} component={NotFound} />
              <Route exact path={["/insight"]} component={NotFound} />
              <Route exact path={["/signin"]} component={NotFound} />
              <Route exact path={["/terms-website"]} component={NotFound} />
              <Route exact path={["/redirection-url"]} component={NotFound} />
              <Route exact path={["/dashboard"]} component={NotFound} />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
