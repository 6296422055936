export const Salutation = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Prof.",
    value: "Prof",
  },
];

export const DobDay = [{ label: "Day", value: "" }];
const startDay = 1;
const endDay = 31;
for (let i = startDay; i <= endDay; i++) {
  let objDobDay = {};
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  DobDay.push(objDobDay);
}

export const DobMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DobYear = [{ label: "Select Year of Birth", value: "" }];
const startYear = 1989;
const endYear = 1910;
for (let k = startYear; k >= endYear; k--) {
  let objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  DobYear.push(objDob);
}
const BannerYearAdv1 = [];
const start = 1926;
const end = 1989;
for (let i = start; i <= end; i++) {
  if (start === i) {
    BannerYearAdv1.push([-i]);
  } else {
    BannerYearAdv1.push([i]);
  }
}
export { BannerYearAdv1 };

export const signCheckText = ` I understand that by clicking "Submit", my eligibility will be
assessed by automated technology. I understand I have a right to
object to this as set out in the
<a href="/assets/docs/Customer-Privacy-Policy.pdf" target="_blank">
  Privacy Policy.
`;

